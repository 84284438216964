import { useContext, useState } from "react";
import Button from "../../Components/Button/Button";
import { DataContext } from "../../Context/DataContext";
import Select from "../../Components/Input/Select/Select";
import Spinner from "../../Components/Loading/Spinner";
import "./JournalPage.css";

interface FailedEntry {
  entry: string;
  index: number;
  reason: any | string;
}

interface EntryResponse {
  status: string;
  entriesAdded: number;
  successPerc: string;
  entriesNotAdded: FailedEntry[];
}

const journalTypes = [
  { value: "journal", text: "Journal" },
  { value: "spiritual", text: "Spiritual" },
  { value: "thought", text: "Thought" },
];

const isDateValid = (date: string) => {
  const test = Date.parse(date);
  if (isNaN(test)) return false;
  return true;
};

const processEntries = (text: string) => {
  let currentDate = new Date();
  const entries = [];
  const lines = text?.split("\n");
  let justSet = false;
  //go through all of the lines in the text file
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    //if the line is a valid date then we will adjust the current date to be the date we find
    if (isDateValid(line.trim())) {
      justSet = true;
      currentDate = new Date(Date.parse(line.trim()));
      //make sure that the line isn't just an empty line
    } else if (line.trim() !== "") {
      //if we just recently reset the date (the lines above) then don't move the date forward as it's already the current date
      if (!justSet) {
        //move the date forward 24 hours
        currentDate = new Date(
          new Date(currentDate).getTime() + 60 * 60 * 24 * 1000
        );
      } else {
        justSet = false;
      }
      //add the entry into the found entries
      entries.push({ entry: line.trim(), date: new Date(currentDate) });
    }
  }
  return entries;
};

const processExperimental = (text: string) => {
  let currentDate = new Date();
  const entries = [];
  const lines = text?.split("\n");
  let entry = "";
  let justSet = false;
  //go through all of the lines in the text file
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    //if the line is a valid date then we will adjust the current date to be the date we find
    if (isDateValid(line.trim())) {
      if (entry !== "")
        entries.push({ entry: entry, date: new Date(currentDate) });
      justSet = true;
      currentDate = new Date(Date.parse(line.trim()));
      entry = "";
      //make sure that the line isn't just an empty line
    } else {
      entry += line + "\n";
    }
  }
  if (entry != "") {
    entries.push({ entry: entry, date: new Date(currentDate) });
  }
  return entries;
};

const JournalEntryPage: React.FC = () => {
  //   const [search, setSearch] = useState("");
  const { fetcher } = useContext(DataContext);
  const [text, setText] = useState("");
  const [type, setType] = useState<"journal" | "spiritual" | "thought">(
    "journal"
  );
  const [experimental, setExperimental] = useState(false);
  const [foundEntries, setFoundEntries] = useState<
    { entry: string; date: Date }[]
  >([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [entriesAdded, setEntriesAdded] = useState(0);
  const [failedEntries, setFailedEntries] = useState<FailedEntry[]>([]);
  const [status, setStatus] = useState("");

  const onProcess = () => {
    console.log(experimental);
    const entries = experimental
      ? processExperimental(text)
      : processEntries(text);
    setFoundEntries(entries);
  };

  const onSubmitEntries = async () => {
    setLoading(true);
    setFailedEntries([]);
    setStatus("");
    const response = await fetcher(`/journal/`, {
      method: "POST",
      body: {
        entries: foundEntries,
        type: type,
      },
    });
    console.log(response);
    if (response?.data?.successPerc) {
      let res = response?.data as EntryResponse;
      if (res?.entriesAdded) {
        setEntriesAdded(res.entriesAdded);
      }
      //if some entries weren't processed then push them onto the stack
      if (res?.entriesNotAdded.length > 0) {
        setFailedEntries(res.entriesNotAdded);
        setError(
          "Some entries were not added, check the provided entries above for more information."
        );
        const unprocessedEntries: { entry: string; date: Date }[] = [];
        for (let i = 0; i < res.entriesNotAdded.length; i++) {
          const entry = foundEntries?.[res.entriesNotAdded[i].index];
          if (entry) unprocessedEntries.push(entry);
        }
        setFoundEntries(unprocessedEntries);
      } else {
        setFoundEntries([]);
      }
      setStatus(
        `added ${res.entriesAdded} entr${
          res.entriesAdded > 1 ? "ies" : "y"
        }, success rate: ${res.successPerc}`
      );
    }
    setLoading(false);
  };

  return (
    <div className="thought-page-container">
      <div className="thought-title-container">
        <h1 className="thought-page-title">Entry</h1>
        {/* <Input
          style={{ maxWidth: "calc(100vw - 20px)", width: "800px" }}
          id="thought-search-bar"
          value={search}
          placeholder={"search"}
          onChange={(val: string) => setSearch(val)}
        /> */}
        <div
          className="flex"
          style={{
            gap: "10px",
            flexWrap: "wrap",
            minWidth: "min(calc(100vw - 40px),400px)",
          }}
        >
          <div className="flex">
            <input
              className="journal-checkbox"
              onChange={(e) => setExperimental(e.target.checked)}
              checked={experimental}
              type="checkbox"
              id="experimental-checkbox"
            />
            <label className="journal-label" htmlFor="experimental-checkbox">
              Process Entries Up To Newline
            </label>
          </div>
          <select
            value={type}
            onChange={(e) => {
              const v = e.target.value;

              if (v === "spiritual" || v === "thought" || v === "journal")
                setType(v);
            }}
            className="journal-select"
          >
            {journalTypes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <textarea
          className="text-area-journal"
          onChange={(e) => setText(e.target.value)}
          value={text}
        ></textarea>
        <Button
          disabled={error !== ""}
          onClick={() => {
            setStatus("");
            setError("");
            onProcess();
          }}
        >
          Process
        </Button>
      </div>

      {/* <div
        className="thought-container"
        // key={`entry-from-${entry.entry_date}-${entry.entry_id}-${i}`}
      ></div> */}
      {foundEntries.map((entry, i) => {
        return (
          <div
            className="thought-container"
            key={`journal-entry-${entry.date.toString()}-${i}`}
          >
            <div className="thought-date-container">
              <span className="thought-date">
                {new Date(entry.date)
                  .toDateString()
                  .split(" ")
                  .splice(1, 3)
                  .join(" ")}
              </span>
            </div>
            {failedEntries?.[i] && (
              <div className="error-text">
                {`Failed to add entry: ${failedEntries[i]?.reason}`}
              </div>
            )}
            <div className="thought-text">{entry.entry}</div>
          </div>
        );
      })}
      {foundEntries.length > 0 && (
        <div className="padding-bottom-20 flex-center">
          <Button disabled={loading} onClick={() => onSubmitEntries()}>
            Submit
          </Button>
          {loading && <Spinner />}
        </div>
      )}
      {status && <div className="success-text">{status}</div>}
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default JournalEntryPage;
