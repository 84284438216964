import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/DataContext";
import Pagination from "../../../Components/Pagination/Pagination";
import { ImageSearch } from "../../../Resources/types";
const baseURL = process.env.REACT_APP_BASE_URL;

interface AIImagePreviewProps {
  onImageSelect: (img: ImageSearch) => void;
}
export const AIImagePreviews: React.FC<AIImagePreviewProps> = ({
  onImageSelect,
}) => {
  const { fetcher } = useContext(DataContext);

  const [images, setImages] = useState<ImageSearch[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);

  const getImages = async () => {
    const results = await fetcher("/search", {
      method: "GET",
      params: {
        search: `images`,
        s: "flux",
        page: page,
        count: count,
      },
    });
    if (results?.data?.[`images`]?.data) {
      setImages(results.data[`images`].data);
      setTotalItems(results.data[`images`].count);
    }
  };

  useEffect(() => {
    getImages();
  }, [page]);

  return (
    <div className="image-gen-preview-container">
      {images.map((image, i) => (
        <img
          onClick={() => onImageSelect(image)}
          key={i + image.name}
          className="igen-preview-image"
          src={baseURL + "/" + image.name}
          alt={image.name}
        />
      ))}
      <div className="ai-pagination-container">
        <Pagination
          currentPage={page + 1}
          pageSize={count}
          totalCount={totalItems}
          id="pagination-thoughts"
          siblingCount={3}
          onPageChange={(nPage: number) => setPage(nPage - 1)}
        />
      </div>
    </div>
  );
};
