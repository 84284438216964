import { useContext, useState } from "react";
import Input from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import useWinSize from "../../../Hooks/useWinSize";
import { AIOptions } from "../utils";
import Spinner from "../../../Components/Loading/Spinner";
import { DataContext } from "../../../Context/DataContext";
import { AIImagePreviews } from "./ImagePreviews";
import { ImageSearch } from "../../../Resources/types";
const baseURL = process.env.REACT_APP_BASE_URL;

export const ImageGen: React.FC = () => {
  const [prompt, setPrompt] = useState<string>("");
  const [model, setModel] = useState<string>("flux-pro-1.1");
  const [width, setWidth] = useState<number>(1024);
  const [height, setHeight] = useState<number>(768);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>("");
  const { isSmall, isDesktop } = useWinSize();
  const { fetcher } = useContext(DataContext);

  const isFlux = model.includes("flux");
  const minSize = isFlux ? 256 : 0;
  const maxSize = isFlux ? 1440 : 10000;
  const stepSize = isFlux ? 32 : 1;
  const aspectRatio = width / height;
  const armulSize = Math.min(
    Math.min(
      !isSmall ? window.innerWidth * 0.6 : window.innerWidth * 0.9,
      window.innerHeight * 0.6
    ),
    400
  );

  const fixSize = () => {
    if (model.includes("flux")) {
      const nWidth = Math.min(Math.max(256, width - (width % 32)), 1440);
      const nHeight = Math.min(Math.max(256, height - (height % 32)), 1440);
      setWidth(nWidth);
      setHeight(nHeight);
    }
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModel(e.target.value);
  };

  const handleSumbit = async () => {
    setImageURL("");
    setLoading(true);
    //API call here
    const results = await fetcher("/ai/image-gen", {
      method: "POST",
      body: {
        prompt: prompt,
        width: width,
        height: height,
        model: model,
      },
    });
    if (results?.data?.hostedImageURL) {
      console.log(results.data.hostedImageURL);
      setImageURL(results.data.hostedImageURL);
    }
    console.log(results);
    setLoading(false);
  };

  return (
    <div className="igen-divider">
      <div className="igen-settings-container col-3">
        <div className="igen-section">
          <h4 className="m-0 mv-5">Model</h4>
          <select
            className="igen-select"
            value={model}
            onChange={handleModelChange}
          >
            {AIOptions.image.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="igen-section">
          <h4 className="m-0 mv-5">Prompt</h4>
          <textarea
            className="text-area-ai"
            value={prompt}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setPrompt(e.target.value)
            }
          ></textarea>
        </div>
        <div className="igen-section">
          <h4 className="m-0 mv-5">Width</h4>
          <input
            className="igen-input-size"
            type="number"
            step={stepSize}
            min={minSize}
            max={maxSize}
            value={width}
            onBlur={() => fixSize()}
            onChange={(e) => setWidth(parseInt(e.target.value))}
          />
          <h4 className="m-0 mv-5">Height</h4>
          <input
            className="igen-input-size"
            type="number"
            value={height}
            step={stepSize}
            min={minSize}
            max={maxSize}
            onBlur={() => fixSize()}
            onChange={(e) => setHeight(parseInt(e.target.value))}
          />
        </div>
        <div className="igen-section">
          <Button
            onClick={handleSumbit}
            disabled={loading}
            className={`mv-5 ${isSmall ? "" : "button-new"}`}
          >
            Submit
          </Button>
        </div>
      </div>
      {/* image section */}
      <div className={`${isDesktop ? "col-6" : "col-9"} ai-flex-center`}>
        <div className="igen-display-container">
          <div
            className="igen-image-container"
            style={{
              width: `${
                aspectRatio > 1 ? armulSize : armulSize * aspectRatio
              }px`,
              height: `${
                aspectRatio > 1 ? armulSize / aspectRatio : armulSize
              }px`,
            }}
          >
            {imageURL && <img className="igen-image" src={imageURL}></img>}
            {loading && <Spinner />}
          </div>
        </div>
      </div>

      <div className={`${isDesktop ? "col-3" : "col-12"}`}>
        <AIImagePreviews
          onImageSelect={(image: ImageSearch) => {
            setPrompt(image.additional_data?.prompt || "");
            setImageURL(baseURL + "/" + image.name);
          }}
        />
      </div>
    </div>
  );
};
